import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { defaultClient as axios } from "@app/support/api_client";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import classnames from "classnames";
import { Searchable } from "../Helpers";
import Button from "react-bootstrap/Button";
import { AutoVisible } from "../Helpers/AutoVisibleListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import styles from "./style.module.scss";
import { Alert } from "react-bootstrap";

type ResultProps = {
  highlighted: boolean;
  url: string;
  children: any[];
};

type RowProps = {
  row: any;
  highlighted: boolean;
};

function Result({ highlighted, url, children }: ResultProps) {
  return (
    <ListGroup.Item className={classnames({ [styles.highlighted]: highlighted })}>
      <a href={url}>
        <Row>{children}</Row>
      </a>
    </ListGroup.Item>
  );
}

function Account({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} highlighted={highlighted}>
      <Col>
        {row.name}
        <div className="small">{row.email}</div>
      </Col>
      <Col xs={4}>
        {row.login}
        <div className="small">ID #{row.id}</div>
      </Col>
    </Result>
  );
}

function Link({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} highlighted={highlighted}>
      <Col>
        {row.name}
        <div className="small">
          {row.contactEmail} - {row.description.slice(0, 50)}
        </div>
      </Col>
      <Col xs={4}>
        {row.login}
        <div className="small">ID #{row.id}</div>
      </Col>
    </Result>
  );
}

function Team({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} highlighted={highlighted}>
      <Col>
        {row.name}
      </Col>
      <Col xs={4}>
        {row.login}
        <div className="small">ID #{row.id}</div>
      </Col>
    </Result>
  );
}

function Respondent({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} highlighted={highlighted}>
      <Col>
        {row.firstName} {row.lastName}
        <div className="small">
          {row.email} - {row.company}
        </div>
      </Col>
      <Col xs={4}>
        {row.passwd}
        <div className="small">ID #{row.id}</div>
      </Col>
    </Result>
  );
}

function Report({ row, highlighted }: RowProps) {
  return (
    <Result url={row.href} highlighted={highlighted}>
      <Col>
        {row.respondentFirstName} {row.respondentLastName}
        <div className="small">{row.reportviewName}</div>
      </Col>
      <Col xs={4}>
        {moment(row.reportDate).format("YYYY-MM-DD")}
        <div className="small">ID #{row.id}</div>
      </Col>
    </Result>
  );
}

function ResultGroup({
  data,
  label,
  highlightedIndex,
  offset,
  component,
}: {
  data: Record[];
  label: string;
  highlightedIndex: number | null;
  offset: number;
  component: React.ComponentType<RowProps>;
}) {
  let row = offset;
  const El = component;
  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <React.Fragment>
          <ListGroup.Item className="bg-primary text-white">{label}</ListGroup.Item>
          {data.map((r) => {
            const active = row++ == highlightedIndex;
            return (
              <AutoVisible active={active} key={r.id}>
                <El row={r} highlighted={active} />
              </AutoVisible>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
interface Record {
  id: number;
  href: string;
}

function SearchResults({
  search,
  highlighted,
  focused,
  selected,
  type,
  waiting,
  setWaiting,
  openRight,
}) {
  const [data, setData] = useState<{
    accounts: Record[];
    folders: Record[];
    links: Record[];
    teams: Record[];
    respondents: Record[];
    reports: Record[];
  }>({ accounts: [], folders: [], links: [], teams: [], respondents: [], reports: [] });

  const [error, setError] = useState<string | null>(null);
  const [noResults, setNoResults] = useState(false);

  // Run this callback when search changes
  useEffect(() => {
    if (search !== "") {
      setWaiting(true);
      axios
        .get("/show.json", {
          params: {
            search: search,
            resource: type,
          },
        })
        .then((res) => {
          setWaiting(false);
          setError(null);
          setData(res.data);
        })
        .catch(() => {
          setError("Internal error running search. Please try again later.");
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  }, [search, setWaiting, type, setError]);

  useEffect(() => {
    setNoResults(
      data.accounts.length === 0 &&
        data.folders.length === 0 &&
        data.links.length === 0 &&
        data.respondents.length === 0 &&
        data.reports.length === 0 &&
        data.teams.length === 0
    );
  }, [data, setNoResults]);

  const allData = data.accounts.concat(data.links).concat(data.respondents);
  const highlightedIndex =
    highlighted && ((highlighted % allData.length) + allData.length) % allData.length;

  useEffect(() => {
    const selectedIndex =
      selected && ((selected % allData.length) + allData.length) % allData.length;
    if (selectedIndex !== null && selectedIndex in allData) {
      window.location.href = allData[selectedIndex].href;
    }
  }, [allData, selected]);

  if (!(focused && search !== "")) return null;

  return (
    <div>
      <ListGroup
        className={classnames({
          [styles.searchResults]: true,
          [styles.onRight]: openRight,
        })}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        {noResults && search !== "" && !error && !waiting && (
          <Alert variant="warning">No results found</Alert>
        )}
        <ResultGroup
          data={data.accounts}
          label="Accounts"
          component={Account}
          offset={0}
          highlightedIndex={highlightedIndex}
        />

        <ResultGroup
          data={data.folders}
          label="Folders"
          component={Account}
          offset={0}
          highlightedIndex={highlightedIndex}
        />

        <ResultGroup
          data={data.links}
          component={Link}
          label="Links"
          offset={data.accounts.length}
          highlightedIndex={highlightedIndex}
        />

        <ResultGroup
          data={data.teams}
          component={Team}
          label="Teams"
          offset={data.teams.length}
          highlightedIndex={highlightedIndex}
        />

        <ResultGroup
          data={data.respondents}
          component={Respondent}
          label="Respondents"
          offset={data.accounts.length + data.links.length}
          highlightedIndex={highlightedIndex}
        />

        <ResultGroup
          data={data.reports}
          component={Report}
          label="Reports"
          offset={data.accounts.length + data.links.length + data.respondents.length}
          highlightedIndex={highlightedIndex}
        />
      </ListGroup>
    </div>
  );
}

function Search(props: {
  openRight?: boolean;
  groupSelector?: boolean;
  value?: string;
  autoFocus?: boolean;
}) {
  const [type, setType] = useState<string>("");
  const [waiting, setWaiting] = useState(false);

  const onTypeChanged = useCallback((e) => setType(e.currentTarget.value), [setType]);
  const { groupSelector, autoFocus, value, openRight } = props;

  return (
    <div className={classnames({ [styles.container]: true })} tabIndex={1}>
      <div className="input-group">
        {groupSelector && (
          <div className="input-group-prepend">
            <select
              name="resource"
              className={`form-control ${styles.selectBox}`}
              onChange={onTypeChanged}
            >
              <option value="">Everything</option>
              <option value="account">Accounts</option>
              <option value="link">Links</option>
              <option value="respondent">Respondents</option>
              <option value="report">Reports</option>
            </select>
          </div>
        )}
        <Searchable
          name="search"
          autoFocus={autoFocus}
          value={value || ""}
          icon={null}
          waiting={waiting}
          delayMs={250}
          placeholder="Search"
          className={styles.searchBox}
        >
          {({ search, highlighted, focused, selected }) => (
            <SearchResults
              search={search}
              highlighted={highlighted}
              focused={focused}
              selected={selected}
              setWaiting={setWaiting}
              type={type}
              openRight={openRight}
              waiting={waiting}
            />
          )}
        </Searchable>
        <div className="input-group-append">
          <Button type="submit">
            <FontAwesomeIcon icon="search" />
          </Button>
        </div>
      </div>
    </div>
  );
}

Search.defaultProps = {
  openRight: false,
  groupSelector: true,
};

export default Search;
